import React,{useState,useEffect} from 'react'
import './home.css';
import GoogleMapReact from 'google-map-react';
import { Slider,Menu, Dropdown  } from 'antd';
import { PieChart } from 'react-minimal-pie-chart';
import { AgChartsReact } from "ag-charts-react";
import logo from '../../assets/logo.jpeg'
import { Link } from "react-router-dom";
import { DownOutlined } from '@ant-design/icons';
import { DatePicker, Space } from 'antd';
import TawkTo from 'tawkto-react'
import TableComponent from '../../components/table/Table';
import 'antd/dist/antd.css';
import Footer from '../../components/footer/Footer';

const { RangePicker } = DatePicker;
function Home() {
  const [show,setShow]=useState(false);
  const [sar,setSar]=useState('Select Imagery Data');
  useEffect(()=>{
   
    var tawk = new TawkTo('623844db1ffac05b1d7f9b10', '1fulspvd6')

    tawk.onStatusChange((status) => 
    {
         console.log(status)
    })
  })
  const options = {
    data: [
      {
        label: "Maize",
        value: 11
      },
      {
        label: "Sukumawiki",
        value: 13
      },
      {
        label: "Beans",
        value: 14
      },
      {
        label: "peas",
        value: 13
      },
      // {
      //   label: "Potatoes",
      //   value: 9
      // },
      // {
      //   label: "Peas",
      //   value: 10
      // },
      // {
      //   label: "Vegetables",
      //   value: 10
      // },
      // {
      //   label: "Trees",
      //   value: 9
      // },
      // {
      //   label: "Spinach",
      //   value:  11
      // },
    ],
    series: [
      {
        type: "pie",
        angleKey: "value",
        labelKey: "label"
      }
    ]
  };

    const AnyReactComponent = ({ text }) => <div>{text}</div>;
   const defaults = {
        center: {
          lat: -1.0586,
          lng: 36.772387
        },
        zoom: 11
      };
  return (

<div className='home'>
  <div className='top'>
    <div className='fd'>
    <img src={logo} alt='logo pic' className='log' />
    <div className='ted'>
     <span style={{fontStyle:'Calibri'}}> MIZANI </span>- Small Scale AI/ML Crop Mapper. 
    </div>

    </div>
  <div className='sign'> SIGN IN</div>
  </div>

<div className='fcontainer'>
<div className='left'>

<div className='map'>
<GoogleMapReact
        bootstrapURLKeys={{ key:'AIzaSyAFK6rboP7e0LSAqSBk1I_Sq9w_ChHLt9k' }}
          defaultCenter={defaults.center}
          defaultZoom={defaults.zoom}
        >
          <AnyReactComponent
            lat={59.955413}
            lng={30.337844}
            text="My Marker"
          />
        </GoogleMapReact>
</div>


</div>
<div className='right'>

<div className="dropdown">
  <div className="dropbtn">{sar}
 
  
  </div>
  <div className="dropdown-content">
    <div onClick={()=>{setSar('Landsat data');setShow(false)}}>Landsat data</div>
    <div  onClick={()=>{setSar('Sentinel 2A/2B');setShow(false)}}>Sentinel 2A/2B</div>
    <div  onClick={()=>{setSar('Sentinel 1A/1B');setShow(false)}}>Sentinel 1A/1B</div>
    <div  onClick={()=>{setSar('Synthetic Aperture Radar (SAR)');setShow(true)}}>Synthetic Aperture Radar (SAR)</div>
    <div  onClick={()=>{setSar('WorldView-2');setShow(false)}}>WorldView-2</div>
  </div>
</div>
<div className='dates'>
<Space direction="vertical" size={12}>
    <RangePicker />
    </Space>
</div>

<div className='slide'>
    <div className='slider_title'>Cloud Masking</div>
<Slider defaultValue={40}   />
</div>
<div className='slide'>
    <div className='slider_title'>Cloud Percentage Cover</div>
<Slider defaultValue={20}   />
</div>
<div className='slide'>
    <div className='slider_title'>Sigma Value</div>
<Slider defaultValue={60}   />
</div>
<div className='instruments'>
<div className='mod'>Instrument Modes</div>
<div className='instru'>
  <div className='che'>
<input type="checkbox" className='check'  name="vehicle1" disabled={!show} value="Bike"/>
{show?<div className='kd'>
  Stripmap(SM)
</div>:<div className='dk'>
  Stripmap(SM)
</div>}
</div>
<div className='che'>
<input type="checkbox"  className='check'  name="vehicle1" disabled={!show} value="Bike"/>
{show?<div className='kd'>
  Interferometric Wide Swadth(IW)
</div>:<div className='dk'>
  Interferometric Wide Swadth(IW)
</div>}
</div>
<div className='che'>
<input type="checkbox"  className='check'  name="vehicle1" disabled={!show} value="Bike"/>
{show?<div className='kd'>
 Extra Wide Swadth(EW)
</div>:<div className='dk'>
 Extra Wide Swadth(EW)
</div>}
</div>
<div className='che'>
<input type="checkbox"  className='check'  name="vehicle1" disabled={!show} value="Bike"/>
{show?<div className='kd'>
Wave
</div>:<div className='dk'>
Wave
</div>}
</div>
</div>
</div>

<div className='dr'>
<div className="dropdown">
  <div className="dropbtn">Machine Learning Algorithims
  <div style={{width:'20px',height:'20px'}} >

<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
<path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
</svg>

</div>
  
  </div>
  <div className="dropdown-content">
    <div>Random Forest</div>
    <div> SVM</div>
    <div>CART</div>
  
  </div>
</div>
  </div>

</div>
</div>
<div className='bts'>
<div className='bt'> 
Run Classification
    </div>
    <Link to='/work'>  <div className='bt'>  
Crop Health Analysis
    </div></Link> 
    <Link to='/work'>
    <div className='bt'> 
Crop Yield Analysis
    </div></Link>
    
    <Link to='/work'>
    <div className='bt'> 
Change Detection
    </div></Link>
    <div className='bt'> 
Import Data
    </div>
    <div className='bt'> 
Export Data
    </div>




</div>
<div className='flex'>
  <div className='fl2'>
  <div className='tc'>
  <div className='chart_titl' style={{fontSize:'20px'}}>
  Crop Mapping Information
</div>
  <TableComponent/>
</div>
  </div>
  <div className='fr2'>
<div className='tru'>
  <div className='chart_title' style={{fontSize:'20px'}}>
  Crop Classification
</div>
  <div className='piechart' >

<AgChartsReact options={options} />
</div>
<div className='run' style={{marginTop:'20px'}}>
<Link to='/work'>  <div className='bt'>  
Play Button
    </div></Link> </div>
</div>
</div></div>
<Footer/>
</div>
  
  )
}

export default Home