import React from 'react'
import './footer.css';
import { AiFillTwitterCircle,AiFillInstagram } from 'react-icons/ai';
import { BsFacebook } from 'react-icons/bs';

function Footer() {
  return (
    <>
    <div className='foo'>

        <div className='left5'>
<div className='fle5 one'>
    <div className='ab5' style={{cursor:'pointer'}}>
        About Us
    </div>
    <ul className='abp5'>
       <a href='https://www.ksa.go.ke/small-scale-crop-mapping'><li  style={{cursor:'pointer'}}>The Project</li></a> 
      <a href='https://www.privacypolicies.com/live/7f475f8c-5dcf-4d1d-a6bb-f79a73acfc1b' > <li style={{cursor:'pointer'}}>Privacy Policy</li></a>
        <a href="mailto:sscmuon@gmail.com"> <li style={{cursor:'pointer'}}>Contact Us</li></a>
    </ul>
</div>
<div className='fle5 two'>
    <div className='ab5' style={{cursor:'pointer'}}>
        Our Partners
    </div>
    <ul className='abp5'>
       <a href='https://www.ksa.go.ke/'> <li style={{cursor:'pointer'}}>KSA</li></a>
       <a href='https://engineering.uonbi.ac.ke/Basicpage/Welcome'> <li style={{cursor:'pointer'}}>UON</li></a>
        <a href='https://drive.google.com/file/d/1SshUgTzHJlsFbDXFUbVHN4hP9UVE8wY-/view?usp=sharing'> <li style={{cursor:'pointer'}}>Mobile Application</li></a>
        <li style={{cursor:'pointer'}}>Gallery</li>
    </ul>
</div>
<div className='fle5 three'>
<div className='ab5' style={{cursor:'pointer'}}>
        Socials
    </div>
    <ul>
    <li style={{cursor:'pointer'}}><BsFacebook  className='twet'/><div>Facebook</div></li>
       <a href='https://www.instagram.com/sscm_uon/?utm_medium=copy_link'> <li style={{cursor:'pointer'}}><AiFillInstagram  className='twet'/> <div>Instagram</div></li></a>
       <a href='https://twitter.com/sscm_uon?t=DBKTLTdjGW_XIh3bUzmd5A&s=08'> <li style={{cursor:'pointer'}}><AiFillTwitterCircle className='twet'/><div>Twitter</div></li></a>
     
    </ul>
</div>
<div className='fle5 four'>
<div className='ab5' style={{cursor:'pointer'}}>
        Address
    </div>
    <ul>
        <li>KSA Small Scale Crop Mapping UON Project Team</li>
        <li>School of Engineering</li>
        <li>University of Nairobi, Main Campus.</li>
        <li>P.O Box 30197-00100</li>
        <li>Nairobi</li>
    </ul>
</div>
        </div> 
        
        <div className='right5'>

        </div>
    </div>
    
    </>
  )
}

export default Footer