import React,{useState} from 'react'
import { Table, Tag, Space,Button } from 'antd';
import'./table.css';

const TableComponent=()=>{
const [selectedRowKeys,setSelectedRowKeys]=useState([]);
const [loading,setLoading]=useState(false);


    const columns = [
        {
          title: <div className='title'>Crop Type</div>,
          dataIndex: 'crop_type',
          key: 'crop_type',
          render: text => <a>{text}</a>,
        },
        {
          title: <div className='title'>Crop Area</div>,
          dataIndex: 'crop_area',
          key: 'crop_area',
          render: text => <a>{text}</a>,
        },
        {
          title: <div className='title'>Crop Health</div>,
          dataIndex: 'crop_health',
          key: 'crop_health',
          render: value => value<=0.25?<div style={{color:'red'}}>{value}</div>:value<=0.5?<div style={{color:'#FFBF00'}}>{value}</div>:value<=0.75?<div style={{color:'brown'}}>{value}</div>:<div style={{color:'green'}}>{value}</div>,

        },
        {
          title: <div className='title'>Crop Yield</div>,
          dataIndex: 'crop_yield',
          key: 'crop_yield',
        },
  
        {
          title: <div className='title'>Expected Crop Yield</div>,
          dataIndex: 'expected_crop_yield',
          key: 'expected_crop_yield',
        },
        {
          title: <div className='title'>User defined Optimal Yield</div>,
          dataIndex: 'user_defined_optimal_yield',
          key: 'user_defined_optimal_yield',
        },
        {
          title: <div className='title'>Estimated Crop Yield</div>,
          dataIndex: 'estimated_crop_yield',
          key: 'estimated_crop_yield',
        },
      
     
      ];
      
      const data = [
        {
          key: '1',
          crop_type:'Maize',
          crop_area: 306,
          crop_yield: '80%',
          crop_health: 0.6,
          expected_crop_yield: 0.8,
          user_defined_optimal_yield:2.3,
          estimated_crop_yield:1.2
        },
        {
          key: '2',
          crop_type:'Potatoes',
          crop_area: 358,
          crop_yield: '75%',
          crop_health: 0.7,
          expected_crop_yield: 2.8,
          user_defined_optimal_yield:1.3,
          estimated_crop_yield:1.3
        },
        {
          key: '3',
          crop_type:'Sukuma Wiki',
          crop_area: 390,
          crop_yield: '50%',
          crop_health: 0.65,
          expected_crop_yield: 2.8,
          user_defined_optimal_yield:1.3,
          estimated_crop_yield:1.3
        },
        {
          key: '4',
          crop_type:'Beans',
          crop_area: 300,
          crop_yield: '70%',
          crop_health: 0.5,
          expected_crop_yield: 2.3,
          user_defined_optimal_yield:1.5,
          estimated_crop_yield:1.1
        },
        {
          key: '5',
          crop_type:'Peas',
          crop_area: 256,
          crop_yield: '65%',
          crop_health: 0.9,
          expected_crop_yield: 2.8,
          user_defined_optimal_yield:1.3,
          estimated_crop_yield:1.3
        },
        {
          key: '6',
          crop_type:'Vegetables',
          crop_area: 278,
          crop_yield: '70%',
          crop_health: 0.8,
          expected_crop_yield: 3.8,
          user_defined_optimal_yield:2.3,
          estimated_crop_yield:2.3
        },
        {
          key: '7',
          crop_type:'Trees',
          crop_area: 298,
          crop_yield: '50%',
          crop_health: 0.6,
          expected_crop_yield: 2.8,
          user_defined_optimal_yield:1.3,
          estimated_crop_yield:1.3
        },
      
      ];
     const start = () => {
        setLoading(true);      
        setTimeout(() => {
          setSelectedRowKeys([]);
          setLoading(false);
         
        }, 1000);
      };
    const  onSelectChange = selectedRowKeys => {
       setSelectedRowKeys(selectedRowKeys);
      };
      const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
      };
      const hasSelected = selectedRowKeys.length > 0;
      return(
          <>
            <div >
       
          <span style={{ marginLeft: 8 }}>
            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
          </span>
        </div>
          <Table rowSelection={rowSelection} columns={columns} dataSource={data}  />
          </>
      )



}
export default TableComponent;
