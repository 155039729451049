import logo from './logo.svg';
import './App.css';
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import home from './pages/home/home';
import work from './pages/work/work';
function App() {
  return (
 <>
    <Router>
      <Switch>
        <Route exact path='/' component={home} />
        <Route exact path='/work' component={work} />
      </Switch>
        </Router>
 
 </>
  );
}

export default App;
